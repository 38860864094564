import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Layout from "../components/layout"
import SEO from "../components/seo"
import bgimage from "../images/hero-background.jpg"
import {
  FiAward,
  FiCode,
  FiTerminal,
  FiActivity,
  FiMail,
  FiGlobe,
} from "react-icons/fi"
import { FaWhatsapp } from "react-icons/fa"

import icondev from "../images/material-icons/code-white-36dp.svg"
import iconnet from "../images/material-icons/router-white-36dp.svg"
import bgdev from "../images/webdev-cover-background.jpg"
import bgnet from "../images/network-cover-background.jpg"
import logo from "../images/logo.png"

const heroBgStyle = {
  color: "blue",
  backgroundImage: "url(" + bgimage + ")",
}

const IndexPage = () => {
  const [tooltip, setTooltip] = useState("Copiar email")

  const renderTooltip = props => (
    <Tooltip id="email-tooltip" {...props}>
      {tooltip}
    </Tooltip>
  )
  return (
    <Layout>
      <SEO title="Home" />
      <div
        className="jumbotron-fluid min-vh-100 index-jumbotron dark text-white text-center"
        style={heroBgStyle}
      >
        <Container>
          <Row>
            <Col>
              <h1>Somos ChalteNet</h1>
              <h2>
                Soluciones en telecomunicaciones, desarrollo web e informática.
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md>
              <AnchorLink to="/#networking">
                <div
                  className="d-flex home-option-cover"
                  style={{ backgroundImage: "url(" + bgnet + ")" }}
                >
                  <div>
                    <img
                      src={iconnet}
                      width="60px"
                      height="60px"
                      className=""
                      alt="Networking"
                    />
                    <h2>Networking</h2>
                  </div>
                </div>
              </AnchorLink>
            </Col>

            <Col md>
              <AnchorLink to="/#webdev">
                <div
                  className="d-flex home-option-cover"
                  style={{ backgroundImage: "url(" + bgdev + ")" }}
                >
                  <div>
                    <img src={icondev} width="60px" height="60px" alt="WebDev"/>
                    <h2>WebDev</h2>
                  </div>
                </div>
              </AnchorLink>
            </Col>
          </Row>
        </Container>
      </div>
      <section className="home-section">
        <Container>
          <a id="quienes-somos">
            <h1>Quiénes somos</h1>
          </a>
          <p>
            Somos un blablablabala lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Integer vel risus consequat, malesuada enim
            faucibus, auctor ante. Vestibulum elementum feugiat enim ut rutrum.
            Aliquam vitae iaculis leo. Pellentesque nec turpis quis elit
            venenatis eleifend. Nullam euismod, ipsum vitae pulvinar laoreet,
            est lectus gravida neque, sed scelerisque odio massa at ligula.
            Integer non tortor nec dui pulvinar tempus eu in ante. Praesent
            commodo auctor tincidunt. Phasellus sed sem vehicula, venenatis quam
            ac, vulputate nisi. Interdum et malesuada fames ac ante ipsum primis
            in faucibus. Phasellus ut tellus ac eros efficitur ultricies. Mauris
            aliquet tristique maximus. Cras nec porta dui, id feugiat se1.
          </p>
        </Container>
      </section>
      <section className="home-section sec-grad1">
        <Container>
          <a id="networking">
            <h1>Servicios de Networking y Telecomunicaciones</h1>
          </a>
          <Row className="mt-4">
            <Col sm className="text-center ">
              <FiAward color="#333" size={32} />
              <p>
                lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vel risus consequat, malesuada enim faucibus, auctor ante.{" "}
              </p>
            </Col>
            <Col sm>
              <FiAward color="#333" size={32} />
              <p>
                Vestibulum elementum feugiat enim ut rutrum. Aliquam vitae
                iaculis leo. Pellentesque nec turpis quis elit venenatis
                eleifend. Nullam eui
              </p>
            </Col>
            <Col sm>
              <FiAward color="#333" size={32} />
              <p>
                Aliquam vitae iaculis leo. Pellentesque nec turpis quis elit
                venenatis eleifend. Nullam eui
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="home-section sec-grad2">
        <Container>
          <a id="webdev">
            <h1>Desarrollo web y Aplicaciones</h1>
          </a>
          <Row className="mt-4">
            <Col sm className="text-center ">
              <FiTerminal color="#333" size={32} />
              <p>
                lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                vel risus consequat, malesuada enim faucibus, auctor ante.{" "}
              </p>
            </Col>
            <Col sm>
              <FiCode color="#333" size={32} />
              <p>
                Vestibulum elementum feugiat enim ut rutrum. Aliquam vitae
                iaculis leo. Pellentesque nec turpis quis elit venenatis
                eleifend. Nullam eui
              </p>
            </Col>
            <Col sm>
              <FiActivity color="#333" size={32} />
              <p>
                Aliquam vitae iaculis leo. Pellentesque nec turpis quis elit
                venenatis eleifend. Nullam eui
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="home-section">
        <Container>
          <h1 class="sr-only ">Contacto</h1>
          <Row className="mt-4 home-contacto">
            <Col
              sm
              className="home-contacto-img text-center text-sm-right pb-4 pb-sm-0"
            >
              <img
                src={logo}
                width="200"
                height="150"
                className="d-inline-block align-top image-to-bnw"
                alt="ChalteNet"
              />
            </Col>
            <Col sm className="d-flex flex-row flex-wrap ">
              <OverlayTrigger
                placement="right"
                delay={{ show: 50, hide: 100 }}
                overlay={renderTooltip}
              >
                <a className="d-block btn-link link w-100"
                  onClick={() => {
                    navigator.clipboard.writeText("info@chaltenet.com")
                    setTooltip(" ¡Copiado! ")
                    return false;
                  }}
                >
                  <div className="d-flex flex-row w-100 flex-grow pr-2">
                    <FiMail color="#333" size={32} />
                    <p className="pl-2">info@chaltenet.com</p>
                  </div>
                </a>
              </OverlayTrigger>
              <Link to="https://wa.me/5492962720720" className="d-block w-100">
              <div className="d-flex w-100 flex-row flex-grow">
                <FaWhatsapp color="#333" size={32} />
                <p className="pl-2">+54 9 2966 123456</p>
              </div>
              </Link>
              <Link to="https://www.chaltenet.com" className="d-block w-100">
              <div className="d-flex w-100 flex-row flex-grow">
                <FiGlobe color="#333" size={32} />
                <p className="pl-2">www.chaltenet.com</p>
              </div></Link>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage
